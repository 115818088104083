import atendimentosService from '@/api/atendimentoModule/atendimentoServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .loadTable(pageConfig)
        .then((result) => {
          commit('LOAD_LIST_ATENDIMENTO', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  insert({ commit }, data) {
    return atendimentosService
      .insert(data)
      .then((result) => result)
      .catch((error) => error)
  },
  insertAvulso({ commit }, data) {
    return atendimentosService
      .insertAvulso(data)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEditAtendimento({ commit }, data) {
    commit('PREPARE_TO_EDIT_ATENDIMENTO', data)
  },
  edit({ commit }, data) {
    return atendimentosService
      .edit(data)
      .then((result) => result)
      .catch((error) => error)
  },
  resetProfession({ commit }) {
    commit('RESET_ATENDIMENTO')
  },
  listaAtendimentoPorIdRegistro({ commit }, id) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .listaAtendimentoPorIdRegistro(id)
        .then((result) => {
          commit('LOAD_LIST_ATENDIMENTO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  async searchProfessional({ commit }, search) {
    try {
      const result = await atendimentosService.searchProfessional(search)
      return result
    } catch (error) {
      return error
    }
  },
  async searchPaciente({ commit }, search) {
    try {
      const result = await atendimentosService.searchPaciente(search)
      return result
    } catch (error) {
      return error
    }
  },

  getAgendaProfissional({ commit }, id) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .getAgendaProfissional(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },

  gerarHorarioPorIdAgenda({ commit }, id) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .gerarHorarioPorIdAgenda(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },

  getAtendimentoById({ commit }, id) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .getAtendimentoById(id)
        .then((result) => {
          commit('PREPARE_TO_EDIT_ATENDIMENTO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  getListaProcedimentoPorIdProcedimento({ commit }, id) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .getListaProcedimentoPorIdProcedimento(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  getListaCamposAtendimentoPorIdAtendimento({ commit }, id) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .getListaCamposAtendimentoPorIdAtendimento(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  getListaProcedimentosPorEspecialidade({ commit }, id) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .getListaProcedimentosPorEspecialidade(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .delete(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  editCamposAdicionais({ commit }, data) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .editCamposAdicionais(data)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  getListaCampoAdicionaisPorIdAtendimento({ commit }, id) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .getListaCampoAdicionaisPorIdAtendimento(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },

  montarAbas({ commit }, id) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .montarAbas(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  montarTela({ commit }, data) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .montarTela(data)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  gerarHorarioPorIdProfissionalEData({ commit }, data) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .gerarHorarioPorIdProfissionalEData(data)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  getQuantidadeVagasDisponiveis({ commit }, data) {
    return new Promise((resolve, reject) =>
      atendimentosService
        .getQuantidadeVagasDisponiveis(data)
        .then((result) => {
          resolve(result.data)
        })
        .catch((err) => reject(err))
    )
  },
  inserirLegado({ commit }, data) {
    return atendimentosService
      .insertLegado(data)
      .then((result) => result)
      .catch((error) => error)
  },
  async filtro({ commit }, pageFilter) {
    try {
      const result = await atendimentosService.filtro(
        pageFilter.pageConfig,
        pageFilter.filter
      )
      commit('LOAD_LIST_ATENDIMENTO', result.data.content)
      return result
    } catch (error) {
      return error
    }
  }
}
