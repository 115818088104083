import comboServices from '@/api/combo/comboServices'

export default {
  loadComboGenero({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboGenero()
        .then((result) => {
          commit('SET_LIST_COMBO_GENERO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboEstadoCivil({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboEstadoCivil()
        .then((result) => {
          commit('SET_LIST_COMBO_ESTADO_CIVIL', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboProfissoes({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboProfissoes()
        .then((result) => {
          commit('SET_LIST_COMBO_PROFISSOES', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboNaturalidades({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboNaturalidades()
        .then((result) => {
          commit('SET_LIST_COMBO_NATURALIDADES', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboNacionalidades({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboNacionalidades()
        .then((result) => {
          commit('SET_LIST_COMBO_NACIONALIDADES', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboUfs({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboUfs()
        .then((result) => {
          commit('SET_LIST_COMBO_UFS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboPrevidenciaSocial({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboPrevidenciaSocial()
        .then((result) => {
          commit('SET_LIST_COMBO_PREVIDENCIA_SOCIAL', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboTipoEndereco({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboTipoEndereco()
        .then((result) => {
          commit('SET_LIST_COMBO_TIPO_ENDERECO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboSituacaoRegistro({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboSituacaoRegistro()
        .then((result) => {
          commit('SET_LIST_COMBO_SITUACAO_REGISTRO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboNaturezaFiscal({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboNaturezaFiscal()
        .then((result) => {
          commit('SET_LIST_COMBO_NATUREZA_FISCAL', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboFormaPagamento({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboFormaPagamento()
        .then((result) => {
          commit('SET_LIST_COMBO_FORMA_PAGAMENTO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboTipoPagamento({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboTipoPagamento()
        .then((result) => {
          commit('SET_LIST_COMBO_TIPO_PAGAMENTO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboFaixaContribuicao({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboFaixaContribuicao()
        .then((result) => {
          commit('SET_LIST_COMBO_FAIXA_CONTRIBUICAO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboDepedenteAssociadoTipo({ commit }, id) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboDepedenteAssociadoTipo(id)
        .then((result) => {
          commit('SET_LIST_COMBO_DEPEDENTES_TIPO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboTaxas({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboTaxas()
        .then((result) => {
          commit('SET_LIST_COMBO_TAXAS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboTipoAtendimento({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboTipoAtendimento()
        .then((result) => {
          commit('SET_LIST_COMBO_TIPOS_ATENDIMENTO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboEspecialidades({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboEspecialidades()
        .then((result) => {
          commit('SET_LIST_COMBO_ESPECIALIDADES', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboTipoProtocolo({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboTipoProtocolo()
        .then((result) => {
          commit('SET_LIST_COMBO_TIPO_PROTOCOLO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboMeioProtocolo({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboMeioProtocolo()
        .then((result) => {
          commit('SET_LIST_COMBO_MEIO_PROTOCOLO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboTypesRegister({ commit }, id) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboTypesRegister(id)
        .then((result) => {
          commit('SET_LIST_COMBO_TYPES_REGISTER', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboUnidadeAtendimento({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboUnidadeAtendimento()
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboUnidadeAtendimentoProfissional({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboUnidadeAtendimentoProfissional()
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboConvenio({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboConvenio()
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboSituacaoAtendimento({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboSituacaoAtendimento()
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboConselhos({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboConselhos()
        .then((result) => {
          commit('SET_LIST_COMBO_CONSELHOS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboEspecialidadesByIdTipoAtendimento({ commit }, id) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboEspecialidadesByIdTipoAtendimento(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboProfissionalByIdEspecialidade({ commit }, id) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboProfissionalByIdEspecialidade(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadUf({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadUf()
        .then((result) => {
          commit('SET_LIST_COMBO_UFS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadMunicipiosPorUf({ commit }, uf) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadMunicipiosPorUf(uf)
        .then((result) => {
          commit('SET_LIST_COMBO_CIDADES', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboProcedimentos({ commit }, uf) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboProcedimentos()
        .then((result) => {
          commit('SET_LIST_COMBO_PROCEDIMENTOS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboSituacao({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboSituacao()
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboProtocolo({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboProtocolo()
        .then((result) => {
          commit('SET_LIST_COMBO_PROTOCOLO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboItem({ commit }, id) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboItem(id)
        .then((result) => {
          commit('SET_LIST_COMBO_ITEM', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboStatusOs({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboStatusOs()
        .then((result) => {
          commit('SET_LIST_COMBO_STATUS_OS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboGrupos({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboGrupos()
        .then((result) => {
          commit('SET_LIST_COMBO_GRUPOS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboRoles({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboRoles()
        .then((result) => {
          commit('SET_LIST_COMBO_ROLES', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboPermissions({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboPermissions()
        .then((result) => {
          commit('SET_LIST_COMBO_PERMISSIONS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboPerfils({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboPerfils()
        .then((result) => {
          commit('SET_LIST_COMBO_PERFILS', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboPermissionsProfile({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboPermissions()
        .then((result) => {
          commit('SET_LIST_COMBO_PERMISSIONS_PROFILE', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboDoencasCid({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboDoencasCid()
        .then((result) => {
          commit('SET_LIST_COMBO_DOENCAS_CID', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboEspecialidadesExames({ commit }, id) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboEspecialidadesExames(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboPerfilProfissional({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboPerfilProfissional()
        .then((result) => {
          commit('SET_LIST_COMBO_PERFIL_PROFISSIONAL', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboEspecialidadesProfissional({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboEspecialidadesProfissional()
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboContas({ commit }) {
    return new Promise((resolve, reject) =>
      comboServices
        .loadComboContas()
        .then((result) => {
          commit('SET_LIST_COMBO_CONTAS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  }
}
