import typeOfPaymentReceiptServices from '@/api/financial/typeOfPaymentReceiptServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) =>
      typeOfPaymentReceiptServices
        .loadTable(pageConfig)
        .then((result) => {
          commit('LOAD_LIST_TYPES_OF_PAYMENTS_RECEIPT', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  insertNewTypeOfPaymentReceipt({ commit }, data) {
    return typeOfPaymentReceiptServices
      .insertNewTypeOfPaymentReceipt(data)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEditTypeOfPaymentReceipt({ commit }, data) {
    commit('PREPARE_TO_EDIT_TYPE_OF_PAYMENT_RECEIPT', data)
  },
  editTypeOfPaymentReceipt({ commit }, data) {
    return typeOfPaymentReceiptServices
      .editTypeOfPaymentReceipt(data)
      .then((result) => result)
      .catch((error) => error)
  },
  resetTypeOfPaymentReceipt({ commit }) {
    commit('RESET_TYPE_OF_PAYMENT_RECEIPT')
  },
  loadComboPlanoContas({ commit }) {
    return new Promise((resolve, reject) =>
      typeOfPaymentReceiptServices
        .loadCombo()
        .then((result) => {
          commit('SET_LIST_COMBO_PLANOS_CONTAS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  delete({ commit }, id) {
    return typeOfPaymentReceiptServices
      .deleteTypeOfPaymentReceipt(id)
      .then((result) => result)
      .catch((error) => error)
  }
}
