import pagamentoRecebimentoServices from '@/api/financialModule/pagamentoRecebimentoServices'

export default {
  loadTable({ commit }, pageConfig) {
    const locale = 'pt-br'
    const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const firstDayDate = firstDay.toLocaleDateString(locale)
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const lastDayDate = lastDay.toLocaleDateString(locale)
    const filter = {
      tipo: pageConfig.tipo,
      dataInicio: firstDayDate,
      dataFinal: lastDayDate,
      situacao: -1,
      campo: ''
    }
    return new Promise((resolve, reject) =>
      pagamentoRecebimentoServices
        .filter(pageConfig, filter)
        .then((result) => {
          commit('LOAD_LIST_PAGAMENTO_RECEBIMENTO', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  insertNewPagamentoRecebimento({ commit }, data) {
    return pagamentoRecebimentoServices
      .insertNewPagamentoRecebimento(data)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEditFinancialAccount({ commit }, data) {
    commit('PREPARE_TO_EDIT_PAGAMENTO_RECEBIMENTO', data)
  },
  editPagamentoRecebimento({ commit }, data) {
    return pagamentoRecebimentoServices
      .editPagamentoRecebimento(data)
      .then((result) => result)
      .catch((error) => error)
  },
  resetFinancialAccount({ commit }) {
    commit('RESET_PAGAMENTO_RECEBIMENTO')
  },
  async getAssocianteForCpf({ commit }, data) {
    return await pagamentoRecebimentoServices
      .getAssocianteForCpf(data)
      .then((result) => result)
      .catch((error) => error)
  },
  async getbyIdForEdit({ commit }, id) {
    return await pagamentoRecebimentoServices
      .getbyIdForEdit(id)
      .then((result) => result)
      .catch((error) => error)
  },
  delete({ commit }, id) {
    return pagamentoRecebimentoServices
      .deletePagamentoRecebimento(id)
      .then((result) => result)
      .catch((error) => error)
  },
  listFinancial({ commit }, pageConfig) {
    return new Promise((resolve, reject) =>
      pagamentoRecebimentoServices
        .listFinancial(pageConfig)
        .then((result) => {
          commit('LOAD_LIST_PAGAMENTO_RECEBIMENTO', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  filterPayment({ commit }, pageFilter) {
    return new Promise((resolve, reject) =>
      pagamentoRecebimentoServices
        .filter(pageFilter.pageConfig, pageFilter.filter)
        .then((result) => {
          commit('LOAD_LIST_PAGAMENTO_RECEBIMENTO', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  async searchRegistros({ commit }, search) {
    try {
      const result = await pagamentoRecebimentoServices.searchRegistros(search)
      return result
    } catch (error) {
      return error
    }
  }
}
