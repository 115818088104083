export default {
  listaPagamentoRecebimentos: [],
  pagamentoRecebimento: {
    id: '',
    registro: '',
    descricao: '',
    observacao: '',
    dataLancamento: null,
    dataVencimento: null,
    dataQuitacao: null,
    valor: 0.0,
    desconto: 0.0,
    juro: 0.0,
    multa: 0.0,
    valorTotal: 0.0,
    valorQuitado: 0.0,
    obs: null,
    tipo: null,
    formaPagamento: null,
    situacao: null,
    ativo: true
  },
  comboSituacao: [
    { id: 0, nome: 'Aberto' },
    { id: 1, nome: 'Vencido' },
    { id: 2, nome: 'Quitado' },
    { id: 3, nome: 'Pago a menor' }
  ],
  TIPO_PAGAMENTO: 0,
  TIPO_RECEBIMENTO: 1
}
