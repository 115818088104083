export default {
  listTypesOfPaymentReceipts: [],
  typeOfPaymentReceipt: {
    descricao: '',
    planoDeConta: {
      id: null
    },
    ativo: true
  },
  comboPlanoConta: []
}
