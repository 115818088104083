export default {
  listBanksAccount: [],
  bankAccount: {
    descricao: '',
    tipoConta: { id: null },
    dataSaldoInicial: '',
    valorSaldoInicial: '',
    agencia: '',
    conta: '',
    banco: {
      id: null
    },
    valorSaldoAtual: 0,
    ativo: true
  },
  tipoContaCombo: [],
  comboBanks: []
}
