export default {
  LOAD_LIST(state, data) {
    state.listaAgendamentos = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.agendamento = data
  },
  RESET(state) {
    state.agendamento = {
      dataInicial: '',
      dataFinal: '',
      horaInicial: '',
      horaFinal: '',
      profissional: '',
      ativo: true,
      qtoVagas: 0,
      repetir: '',
      domingo: false,
      segunda: true,
      terca: true,
      quarta: true,
      quinta: true,
      sexta: true,
      sabado: false,
      semana: 0
    }
  }
}
