import services from '@/api/atendimentoModule/agendamentoServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) =>
      services
        .loadTable(pageConfig)
        .then((result) => {
          commit('LOAD_LIST', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  insert({ commit }, data) {
    return services
      .insert(data)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEdit({ commit }, data) {
    commit('PREPARE_TO_EDIT', data)
  },
  edit({ commit }, data) {
    return services
      .edit(data)
      .then((result) => result)
      .catch((error) => error)
  },
  reset({ commit }) {
    commit('RESET')
  },
  getById({ commit }, id) {
    return new Promise((resolve, reject) =>
      services
        .getById(id)
        .then((result) => {
          commit('PREPARE_TO_EDIT', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  async searchProfessional({ commit }, search) {
    try {
      const result = await services.searchProfessional(search)
      return result
    } catch (error) {
      return error
    }
  },
  async agendasById({ commit }, id) {
    try {
      const result = await services.agendasById(id)
      return result
    } catch (error) {
      return error
    }
  },
  async bloquearDataById({ commit }, id) {
    try {
      const result = await services.bloquearDataById(id)
      return result
    } catch (error) {
      return error
    }
  },
  async desbloquearDataById({ commit }, id) {
    try {
      const result = await services.desbloquearDataById(id)
      return result
    } catch (error) {
      return error
    }
  },
  async carregarAtendimentos({ commit }, data) {
    try {
      const result = await services.carregarAtendimentos(data)
      return result
    } catch (error) {
      return error
    }
  },
  async editAgenda({ commit }, obj) {
    try {
      const result = await services.editAgenda(obj)
      return result
    } catch (error) {
      return error
    }
  },
  async controleVagasEspecialidade({ commit }, obj) {
    try {
      const result = await services.controleVagasEspecialidade(obj)
      return result
    } catch (error) {
      return error
    }
  },
  async getControleQtdVagas({ commit }, id) {
    try {
      const result = await services.getControleQtdVagas(id)
      return result
    } catch (error) {
      return error
    }
  },
  async filtro({ commit }, pageFilter) {
    try {
      const result = await services.filtro(
        pageFilter.pageConfig,
        pageFilter.filter
      )
      commit('LOAD_LIST', result.data.content)
      return result
    } catch (error) {
      return error
    }
  }
}
