import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/financeiro/contas${queryUrl}`)
  },
  insertNewBankAccount(data) {
    return axios.post('/financeiro/contas', data)
  },
  editBankAccount(data) {
    return axios.put(`/financeiro/contas/${data.id}`, data)
  },
  deleteBankAccount(id) {
    return axios.delete(`/financeiro/contas/${id}`)
  },
  loadComboTipoConta() {
    return axios.get('/financeiro/tipo_contas/lista')
  },
  loadComboBanks() {
    return axios.get('/financeiro/bancos/lista')
  }
}
