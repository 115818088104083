export default {
  LOAD_LIST_PAGAMENTO_RECEBIMENTO(state, data) {
    state.listaPagamentoRecebimentos = data
  },
  PREPARE_TO_EDIT_PAGAMENTO_RECEBIMENTO(state, data) {
    state.pagamentoRecebimento = data
  },
  RESET_PAGAMENTO_RECEBIMENTO(state) {
    state.pagamentoRecebimento = {
      id: '',
      registro: '',
      descricao: '',
      observacao: '',
      dataLancamento: null,
      dataVencimento: null,
      dataQuitacao: null,
      valor: 0.0,
      desconto: 0.0,
      juro: 0.0,
      multa: 0.0,
      valorTotal: 0.0,
      valorQuitado: 0.0,
      obs: null,
      tipo: null,
      formaPagamento: null,
      situacao: null,
      ativo: true
    }
  }
}
