export default [
  {
    path: '/modules/Attendance/attendance',
    redirect: '/modules/Attendance/attendance/Attendance',
    name: 'AttendancePage',
    component: () =>
      import('@/views/modules/attendance/attendance/Attendance.vue'),
    meta: {
      action: 'ROLE_ATEND_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Atendimento',
      breadcrumb: [
        {
          text: 'Attendance'
        }
      ]
    },
    children: [
      {
        path: 'AttendanceProfissional',
        name: 'AttendanceProfissional',
        component: () =>
          import('@/views/modules/profissional/GridProfissional.vue'),
        meta: {
          pageTitle: 'Atendimentos',
          action: 'ROLE_PROFISSIONAL',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Profissional | Atendimentos'
            }
          ]
        }
      },
      {
        path: 'Attendance',
        name: 'Attendance',
        component: () =>
          import(
            '@/views/modules/attendance/attendance/grid/AttendanceGrid.vue'
          ),
        meta: {
          pageTitle: 'Atendimentos',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Atendimento | Atendimentos'
            }
          ]
        }
      },
      {
        path: 'agendamento',
        name: 'agendamento',
        component: () =>
          import(
            '@/views/modules/attendance/agendamento/grid/AgendamentoGrid.vue'
          ),
        meta: {
          pageTitle: 'Agendas',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Atendimento | Agendamentos'
            }
          ]
        }
      },
      {
        path: 'agendamentoLote',
        name: 'agendamentoLote',
        component: () =>
          import(
            '@/views/modules/attendance/agendamentoLote/grid/AgendamentoLoteGrid.vue'
          ),
        meta: {
          pageTitle: 'Agendamentos',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Atendimento | Agendamentos Em Lote'
            }
          ]
        }
      },
      {
        path: 'agendamento/edit/:id',
        name: 'agendamento/edit',
        component: () =>
          import('@/views/modules/attendance/agendamento/form/Form.vue'),
        meta: {
          pageTitle: 'editar Agendamentos',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Atendimento | editar Agendamentos'
            }
          ]
        }
      },
      {
        path: 'agendamentoEmLote/edit/:id',
        name: 'agendamentoEmLote/edit',
        component: () =>
          import('@/views/modules/attendance/agendamentoLote/form/Form.vue'),
        meta: {
          pageTitle: 'editar Agendamentos Em Lote',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Atendimento | editar Agendamentos Em Lote'
            }
          ]
        }
      }
    ]
  }
]
